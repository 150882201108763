<template>
  <div>
    <!--
    <div class="divider">
      <div class="ant-steps-item-container"></div>
      <div class="ant-steps-item-icon">
        <span class="ant-steps-icon">1</span>
      </div>
      <div class="ant-steps-item-content">
        <div class="ant-steps-item-title">Данные для личного кабинета</div>
      </div>
      <br />
      <br />
    </div>
    <div class="content-container">
      <ProviderInfoForUser />
    </div>
    -->
    <div class="divider">
      <div class="ant-steps-item-container"></div>
      <div class="ant-steps-item-icon">
        <span class="ant-steps-icon">1</span>
      </div>
      <div class="ant-steps-item-content">
        <div class="ant-steps-item-title">Данные поставщика</div>
      </div>
      <br />
      <br />
    </div>
    <div class="content-container">
      <ProviderData ref="clientDataComponent" />
    </div>
    <!--
    <template v-if="clientTypeId === '1'">
      <div class="divider">
        <div class="ant-steps-item-container"></div>
        <div class="ant-steps-item-icon">
          <span class="ant-steps-icon">3</span>
        </div>
        <div class="ant-steps-item-content">
          <div class="ant-steps-item-title">Контактная информация</div>
        </div>
        <br />
        <br />
      </div>
      <div class="content-container">
        <ProviderInfo />
      </div>
    </template>
-->
    <ButtonsHolder :callBack="handleAcrtionProviderData" />
  </div>
</template>

<script>
import ProviderData from "@/components/provider/ProviderData"
//import ProviderInfo from "@/components/provider/ProviderInfo"
//import ProviderInfoForUser from "@/components/provider/ProviderInfoForUser"
import ButtonsHolder from "@/components/clients/ButtonsHolder"
import { mapMutations, mapActions, mapGetters } from "vuex"
//import { API_PREFIX } from "@/services/api/v1/const"
export default {
  name: "CreateNewProvider",
  mounted() {
    this.setCreateForm({
      name: "",
      phone: "",
      head_name: "",
      head_position: "",
      legal_name: "",
      legal_address: "",
      physical_address: "",
      inn: "",
    })
  },
  components: {
    ProviderData,
    // ProviderInfo,
    ButtonsHolder,
    // ProviderInfoForUser,
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({
      createSupplier: "suppliers/createSupplier",
    }),
    checkClient() {
      this.$refs.clientDataComponent.$v.fields.$touch()
      // this.$refs.clientInfoForUser.$v.fields.$touch()
    },
    ...mapMutations({
      setCreateForm: "suppliers/SET_CREATE_FORM",
    }),
    async handleAcrtionProviderData() {
      await this.addNewSupplier()
    },
    async addNewSupplier() {
      let postData = {}
      this.$refs.clientDataComponent.$v.fields.$model.forEach(p => {
        postData[p.createclientname] = p.value
      })

      await this.createSupplier(postData)
      await this.$router.push(`/providers`)
    },
  },
}
</script>

<style scoped>
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#advanced-search .ant-form {
  max-width: none;
}
#advanced-search .search-result-list {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding: 20px 40px;
}
.search-result-text {
  margin: 20px 0 10px 0;
}
.tabsinfo {
  min-height: 570px;
}
.buttonsholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 auto;
}
.selects {
  width: 200px;
}
.optGroupSelect {
  margin-top: 10px;
}
.newClientHeader {
  font-weight: bold;
}
.ant-collapse-header {
  font-size: 16px !important;
  font-weight: 700 !important;
}
</style>
<style lang="scss" scoped>
.divider {
  margin-bottom: -30px;
  &:not(:first-child) {
    margin-top: 30px;
  }
}
</style>
