<template>
  <div class="buttons-holder">
    <a-button
      type="primary"
      class="clientinfo-button"
      @click="write"
      size="large"
    >
      Сохранить
    </a-button>
  </div>
</template>

<script>
export default {
  name: "ButtonsHolder",
  computed: {},
  props: {
    callBack: {
      type: Function,
      require: true,
    },
  },
  methods: {
    write() {
      this.$props.callBack()
    },
  },
}
</script>

<style lang="scss" scoped>
.buttons-holder {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 15px 0;
}
.clientinfo-button {
  margin: 0 5px;
}
</style>
